@import "./components/style/variable.scss";

.col12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.col10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
  flex-wrap: wrap;
}
.bat_modals_active {
  visibility: visible !important;
}
.bat_modals_container {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(252, 252, 252, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "poppins";
  font-weight: 500;
  font-size: 14px;
  top: 0;
}

.bat_modals_title {
  h5 {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 15px !important;
  }
  p {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 15px !important;
  }
}

.bat_modals_authentificating {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 600px;
  height: 200px;
  background-color: $bkg;
  position: absolute;
  border-radius: 8px;
  margin: auto;

  .bat_modals_title {
    margin-top: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    h5 {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  @media (min-width: 1600px) {
    .bat_modals_titlecontent {
      margin-bottom: 15px !important;
      margin-top: 0px !important;
    }
  }

  .bat_modal_margin2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto !important;
    .bat_modals_title {
      margin-top: 0px !important;
      display: flex;
      justify-content: center;
      align-items: center;

      h5 {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .bat_modals_import {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.bat_modals {
  width: 600px;
  background-color: $bkg;
  position: absolute;
  border-radius: 8px;
  margin: auto;
}
.bat_modal_margin {
  margin: auto !important;
  margin-top: 40px !important;
  margin-bottom: 30px !important;
}
.creation_import_preview {
  height: 25%;
}
.bat_modals_close {
  position: absolute;
  top: -20px;
  right: -5px;
  .bat_modals_closebtn {
    //background-image: url("./../../../images/CloseBtnModals.svg");
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    height: 32px;
    width: 32px;
    background-color: transparent;
    border: none;
    outline: none;
  }
}

@media (min-width: 1600px) {
  .bat_modals {
    width: 900px;
    height: 680px;
    background-color: $bkg;
    position: absolute;
    border-radius: 8px;
    margin: auto;
  }
}
@media (min-width: 1600px) {
  .bat_modals_title {
    h5 {
      margin-bottom: 40px !important;
      margin-top: 60px !important;
    }
  }

  .bat_modals_title {
    p {
      font-size: 18px !important;
    }
  }
}
