@import "./variables.scss";
//@import "./annimations/Allprojects_annimantions.scss";
// Class d'appel

.col_12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.col_11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col_10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col_9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col_8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col_7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col_6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col_5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col_4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col_3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col_2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col_1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.table {
  overflow: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  margin-top: 0%;
  table {
    width: 100%;
    height: 100%;
    thead {
      tr {
        height: 100% !important;
      }
    }

    tr {
      transition: 0.2s;
      height: 40px;
      width: 100%;
      padding: 0px !important;
      height: calc(47px + 1.7vw);
      display: flex;
      align-items: center;
      /* justify-content: flex-end; */
      background-color: #fff;
      color: rgba(0, 0, 0, 0.95);
      max-height: 6vw;
      margin: 0;
      border-radius: 6px;
      border-bottom: 1px solid #e4e4e4;
      position: relative;
      td {
        padding: 0px;
        position: relative;
        width: 100%;
        height: 100%;
        .onecell {
          padding: 0px;
          display: flex;
          position: relative;
          width: 100%;
          height: 100%;
          .client_name {
            padding: 0px;
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 100%;
            height: 100%;
            p {
              width: 100%;
              padding-left: 10px;
              padding-right: 10px;
              font-size: calc(9px + 0.45vw);
              font-weight: 400;
              font-family: "Work sans";
              text-align: center;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
      .logcontent {
        width: 100% !important;
        display: flex;
        flex-wrap: nowrap;
        margin-right: 0px !important;
        .client {
          padding: 0;
        }
      }
      a:hover {
        color: white;
      }
      .logcontent:hover {
        color: white !important;
      }

      .log,
      .logcontent {
        margin-right: 2px;

        width: 100%;
        //  margin-bottom: 4px;

        background-color: rgba(0, 0, 255, 0);
        border-radius: 2px 2px 2px 2px;
        .last_log {
          display: flex;
          justify-content: space-between;
          text-transform: uppercase;
          color: var(--B_W);
          font-size: var(--littlefont);
          font-weight: 400;
          display: flex;
          align-items: center;
          padding-left: 25px !important;
          padding-right: 15px !important;
          width: 100%;
          height: 40px;
          background-color: rgb(0, 0, 0);
          border-radius: 2px 2px 2px 2px;

          .status_preventif {
            height: 10px;
            width: 10px;
            background-color: var(--selected);
            border-radius: 30px;
          }

          .status_warning {
            height: 10px;
            width: 10px;
            background-color: #fca73e;
            border-radius: 30px;
          }
          .status_alert {
            height: 10px;
            width: 10px;
            background-color: #fd5554;
            border-radius: 30px;
          }
        }
        .message {
          display: flex;
          justify-content: space-between;
          text-transform: uppercase;
          color: var(--B_W);
          font-size: var(--veryverylittlefont);
          font-weight: 400;
          display: flex;
          align-items: center;
          padding-left: 0px !important;
          padding-right: 0px !important;
          width: 100%;
          height: 40px;

          border-radius: 2px 2px 2px 2px;

          p {
            font-weight: 400;
            text-align: center;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .btn {
            justify-content: center;
            color: var(--B_W);
            font-size: var(--veryveryverylittlefont);
            font-weight: 400;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            width: 110%;

            display: flex;
            height: 30px;
            border-radius: 3px;
            background-color: rgb(0, 0, 0);
            border: 1px solid rgba(255, 255, 255, 0.4);
            &:hover {
              background-color: rgba(255, 255, 255, 0.25);
            }
          }
        }
      }

      .top {
        width: 100%;
        height: 40px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 2px 2px 0px 0px;
      }
      .client {
        .client_name {
          justify-content: space-around;

          font-size: var(--littlefont);
          font-weight: 400;
          text-transform: uppercase;
          display: flex;
          align-items: center;

          width: 100%;
          height: 40px;

          border-radius: 0px 0px 2px 2px;
          padding-left: 5px !important;
        }
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 10px);
          padding-right: 15px !important;
          padding-left: 15px !important;
          font-size: var(--veryverylittlefont);
        }
      }
      .nom {
        justify-content: center;
        color: var(--B_W);
        font-size: var(--littlefont);
        font-weight: 400;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        width: 100%;
        height: 40px;

        border-radius: 0px 0px 2px 2px;
      }

      .machine {
        margin-right: 2px;
        height: 50px;
        width: 15%;
        border-radius: 2px;

        .number {
          color: var(--B_W);
          font-size: var(--veryveryverylittlefont);
          font-weight: 400;
          display: flex;
          align-items: center;
          padding: 0px 15px !important;
          width: 100%;
          height: 20px;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 2px 2px 0px 0px;
        }
      }
      .buttons {
        height: 40px;
        width: calc(205px + 0.3vw);
        border-radius: 2px;
        justify-content: center;
        align-content: center;
      }
    }
  }
}

.agrandir_number {
  width: 100%;
  animation-duration: 0.3s;
  animation-name: agrandirN;
}
.reduire_number {
  width: 71%;
  animation-duration: 0.3s;
  animation-name: reduireN;
}
.agrandir_allproject {
  width: 100%;
  animation-duration: 0.3s;
  animation-name: agrandir;
}
.reduire_allproject {
  width: 70%;
  animation-duration: 0.3s;
  animation-name: reduire;
}
// ----------------------
.Allproject_projects {
  height: 100%;
  background-color: $bkg;
  padding-top: 10px;
}
.Allproject_projects_filter_hide {
  height: calc(100% - 50px);
  background-color: $bkg;
  padding-top: 20px;
}
.bottom {
  margin: 0;
  padding: 0;
  height: calc(100%);
  position: relative;
}
.bottom_show {
  height: calc(100% - 33px) !important;
}
.Allproject_title {
  font-size: calc(15px + 0.6vw);
  font-weight: 500;
  margin-bottom: calc(15px + 0.6vw);
}

//STATES Current all archived
.paddingleft {
  padding-left: 15px;
}
.Allproject_state {
  text-align: center;
  border: 1.5px solid #dee1e6;
  margin-bottom: 10px;
  border-radius: 12px;
  padding: 0;
  height: 28px;
  display: flex;
  cursor: pointer;
  button {
    background: none;
    border: none;
    color: #777777;
    font-size: $font8_3;
  }
  .Allproject_state_select {
    width: 100%;
    margin: -1.5px;
    border: 1.5px solid #dee1e6;
    border-radius: 12px;
    cursor: default;
  }
}

.Allproject_projects_section {
  margin: 0;
  width: 100%;
  background-color: #fafafd;

  height: inherit;
  position: relative;
  border-radius: 6px;
  .importhover {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #d7e0ffe3 !important;
    border: 1px solid #bdcaffa2 !important;
    width: 100%;
    height: 100%;
    z-index: 3;
    border-radius: 6px;
    height: calc(100% - 47px);

    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.1s;
    .pointillet {
      margin: 0;
      padding: 0;
      position: absolute;
      width: calc(100% - 34px);
      height: calc(100% - 34px);
      transition: 0.1s;
      //  border: 4px dashed #ffffffa2 !important;

      display: inline-block;
      padding: 20px;
      /* Fallback dashed border
       - the 4px width here is overwritten with the border-image-width (if set)
       - the border-image-width can be omitted below if it is the same as the 4px here
    */
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        //background-image: url("../../../images/Book/importwhite.svg");
        background-size: 56px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .importhoverwhite {
    background-color: #ffffffec !important;

    .pointillet {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D7E0FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      .icon {
        //background-image: url("../../../images/Book/importblue.svg");
      }
    }
  }
  .displaynone {
    display: none !important;
  }
  .Allproject_projects_content {
    margin: 0;
    padding-right: 0px;
    padding-left: 4px;

    height: calc(100% - 55px);
    display: block;
    overflow: scroll;

    margin-bottom: 4px;
    position: relative;
  }
  .Allproject_projects_content::-webkit-scrollbar:vertical {
    display: block;
    width: 5px;
    border-radius: 20px;
  }
  .Allproject_projects_content::-webkit-scrollbar:hover {
    display: block;
    width: 7px !important;
    border-radius: 20px;
  }
  .Allproject_projects_content::-webkit-scrollbar-thumb {
    border-right-width: 5px;
    border-radius: 30px;
    background-color: #d9d9d9;
    /* border-top-right-radius: 9px 5px; */
    /* border-bottom-right-radius: 9px 5px; */
  }
  .Allproject_projects_content::-webkit-scrollbar-thumb:hover {
    background-color: #aaaaaa;
  }
}
.Allproject_project_section {
  margin: 0;
  height: 100%;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.Allproject_projects_section_sort {
  background-color: $bkg;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  box-shadow: 1px 1px 3px #ededed;
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #f1f1f154;
  border-radius: 6px;
  font-size: $font9;
  font-weight: 500;
  color: black;
  align-items: center;
  .bat_checkmarkactive2 {
    left: calc(14px + calc(0.25% + 0.45vw)) !important;
  }
  .sort_options {
    //  width: calc(22px + 0.4vw);

    display: flex;
    align-items: center;
    position: absolute;
    left: calc(15px + calc(1.55% + 0.45vw));
    z-index: 1;
    transition: 0.3s;

    // display: none;
    // permet de cacher le multi selection
    button {
      border: 1px solid #777777;
      background-color: white;
      width: 100%;
      height: calc(19px + 0.4vw);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      padding: 0;
      margin-top: -14px;
      img {
        width: 45%;
      }
    }
    .bat_checkmark {
      margin-top: -14px;
    }

    input[type="checkbox"] {
      border: 2px solid #5e5e5e;
    }
    [type="checkbox"]:checked {
      border: 3px solid #dadada;
    }
  }
  .sort_list_active {
    display: block !important;
  }
  .sort_list {
    display: none;
    background-color: white;
    box-shadow: 3px 5px 7px #d4d4d4;
    li {
      padding-left: 15px;
      padding-top: 7px;
      padding-bottom: 7px;
      cursor: pointer;
    }
    li:hover {
      color: $blue;
      transform: scale(1.01);
    }
    .sort_active {
      color: $blue;
    }
  }
}

// ajouter la bordure bleu lors du clic sur un projet
.Allproject_project_active {
  border: 1px solid #d5dbea !important;
  background-color: #d7e0ffde !important;
  font-weight: 500 !important;
  color: black;
  span {
    background-color: #dbe4ff !important;
    a:hover {
      background-color: #ccd9ff;
    }
    a:active {
      background-color: #b6c6f8;
    }
  }
}
.Allproject_project {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-size: calc(9px + 0.45vw);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.95);
  height: calc(100% - 140px);
  width: calc(100% - calc(10px + 10vw));
  flex-wrap: wrap;
  margin-bottom: 0px;
  margin-top: 0px;
  border-radius: 6px;
  font-family: "Work sans";
  border-bottom: 1px solid #e4e4e4;
  &:hover {
    border: 1px solid #b8b8b8;
    border-top: 1px solid #e0e0e0;
    font-weight: 500;
    color: black;
  }
  &:hover:last-child {
    box-shadow: none !important;
    border: none !important;
  }
  div {
    padding: 0;
    margin: 0;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 1px;
  }
  .table {
    //height: calc(100% - 140px);
    //margin-top: 64px;
    //width: calc(100% - calc(10px + 10vw));
  }
  td {
    padding: 0;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    input[type="text"] {
      font-size: calc(9px + 0.45vw);
      font-weight: 400;
      color: rgba(0, 0, 0, 0.95);
      font-family: "Work sans";
      border: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      background-color: transparent;
    }
    input::placeholder {
      opacity: 0.5;
    }
  }
}
th {
  padding: 0;
  span {
    margin-left: 5px;
  }
}
.Allproject_padding {
  width: 100%;
  display: flex;
  padding-right: 19px;
  padding-left: 19px;
  background-color: $bkg;
  display: flex;
  align-items: center;
  border-radius: 6px;
  .Allproject_projects_sort {
    text-align: center;
    border: none;
    border-right: 1px solid #d8d8d8;

    background-color: $bkg;
    height: calc(45px);

    font-size: calc(11px + 0.45vw);
    font-weight: 500;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    &IMG {
      margin-left: 10px;
    }
    span {
      margin-left: 5px;
      img {
        width: 9px;
      }
    }
  }
  .Allproject_projects_sort:last-child {
    border-right: 1.5px solid transparent;
  }
  button {
    background-color: $bkg;
    border-right: 1.5px solid #dee1e6;
    border: none;
  }
}
.Allproject_project_date {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 5vw !important;
  padding-left: 3px;
  transition: 0.3s;
}
.Allproject_project_dateopen {
  padding-right: 3vw !important;
}
@media (min-width: 1600px) {
  .Allproject_project_date {
    padding-right: 5vw !important;
  }
  .Allproject_project_dateopen {
    padding-right: 3.5vw !important;
  }
}
@media (max-width: 1600px) {
  .Allproject_project_date {
    padding-right: 3vw !important;
  }
  .Allproject_project_dateopen {
    padding-right: 1.8vw !important;
  }
}
@media (max-width: 900px) {
  .Allproject_project_date {
    padding-right: 1.5vw !important;
  }
  .Allproject_project_dateopen {
    padding-right: 0.8vw !important;
  }
}
//CHECKBOX

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  border-radius: 2px;
  outline: none;
  border: 1px solid #767676;
  transition: 0.3s;
  width: 20px !important;
  height: 20px;
  position: absolute !important;
  margin-left: 15px;
  z-index: 10;
}
.bat_checkmarkactive {
  margin-right: 10px !important;
  transition: 0.3s;
}
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  left: 0;
  position: relative;
  cursor: pointer;
}
[type="checkbox"]:not(:checked) {
  background-color: transparent;
}
[type="checkbox"]:checked {
  background-image: url(../../image/icon/checked.png);
  background-size: 70%;
  filter: invert(0.9);
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid #bdbdbd;
}

.bat_checkmark {
  height: calc(13.5px + 0.28vw);
  width: calc(13.5px + 0.28vw);
}
.Allproject_project_step {
  display: flex;
  flex-wrap: wrap;
  padding-right: 20px !important;
  padding-left: 20px !important;
}
//Couleurs des bulets status
.Allproject_project_step_start {
  border: 1px solid #afafb0;
  height: calc(9px + 0.3vw);
  width: calc(9px + 0.3vw);
  border-radius: 100px;
}
.Allproject_project_step_start_active {
  background-color: $blue;
}
.Allproject_project_step_wait {
  border: 1px solid black;
  height: calc(11px + 0.4vw);
  width: calc(11px + 0.4vw);
  border-radius: 100px;
  margin: auto;
}
.Allproject_project_step_wait_active {
  background-color: #ffc000;
  border: none;
}

.Allproject_project_step_ready_active {
  background-color: #4149f6;
  border: none;
}

.Allproject_project_step_end_active {
  background-color: #38d0bf;
  border: none;
}

.Allproject_project_step_end {
  border: 1px solid #afafb0;
  height: calc(11px + 0.4vw);
  width: calc(11px + 0.4vw);
  border-radius: 100px;
  margin: auto;
}
.Allproject_project_step_end_approuve {
  background-color: #2efdbd;
}
.Allproject_project_step_decline_active {
  background-color: red;
}
// spiner

// =============================
// Loader
// =============================

$loader-colors: rgb(65, 73, 246);
$loader-dash: 60;
$loader-duration: length($loader-colors) * 1.9s;
$loader-duration-alt: $loader-duration / length($loader-colors);
$loader-keyframe: 1 / (length($loader-colors) * 2) * 100;
.loaderzone {
  margin-right: 0 !important;
}
.loader {
  animation: loader-turn $loader-duration-alt linear infinite;
  padding: 0;
  margin-left: 0px !important;
  max-width: 60px;
  width: calc(13px + 0.3vw) !important;

  right: 0;

  @keyframes loader-turn {
    0% {
      transform: rotate(-90deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(630deg);
    }
  }
}

.loader__value {
  animation: loader-stroke $loader-duration linear infinite;
  fill: none;
  stroke-dasharray: $loader-dash;
  stroke-dashoffset: $loader-dash;
  stroke-linecap: round;
  stroke-width: 4;

  @for $i from 1 through length($loader-colors) {
    &:nth-child(#{$i}) {
      stroke: nth($loader-colors, $i);

      @if $i > 1 {
        animation-delay: ($i - 1) * $loader-duration-alt;
      }
    }
  }

  @keyframes loader-stroke {
    #{$loader-keyframe * 1%} {
      stroke-dashoffset: 0;
    }
    #{$loader-keyframe * 2%},
    100% {
      stroke-dashoffset: $loader-dash;
    }
  }
}
//End spiner

.Allproject_project_action,
.Allproject_project_action:hover {
  color: black;
  padding-left: calc(7px + 0.1vw);
  padding-right: calc(7px + 0.1vw);
  padding-top: calc(3px + 0.1vw);
  padding-bottom: calc(3px + 0.1vw);
  font-size: $font9;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 6px;
}

.Allproject_project_add {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  height: 0px !important;
  margin: 0;
}
.Allproject_project_Addicon {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: default;
  .Allproject_project_icon {
    cursor: pointer;
    border-radius: 500px;
    height: calc(35px + 1vw);
    width: calc(35px + 1vw);

    font-size: calc(18px + 1vw);
    border: 1px solid $blue;
    background-image: url(../../image/icon/plus2.svg);
    background-color: $blue;
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: -28px;

    position: fixed;
    bottom: 35px;
    z-index: 10;

    a {
      color: black;
    }
    .Allproject_project_iconText {
      width: 100%;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      border: 1px solid #d2d2de;
      border-radius: 500px;
      height: calc(100%);
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-family: "Poppins";
        font-size: 18px;
        margin: 0;
      }
    }
  }
  .Allproject_project_icon:hover {
    border: 1px solid transparent;
    overflow: visible;

    .Allproject_project_iconText {
      opacity: 1;
      width: 250px;
      visibility: visible;
      position: absolute;
      border: 1.5px solid $blue;
      border-radius: 500px;
      height: calc(100% + 2px);
      background-color: $blue;
      left: 0;
      top: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        color: white;
      }
    }
  }
}

.Allproject_action_column {
  height: calc(100% - 2px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  p {
    flex: 0 0 calc(100% - 40px);
    max-width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    font-size: calc(9px + 0.45vw);
    font-weight: 400;
    padding-right: 5px;
  }
}
// ACTIONS

.IMGmodifier {
  height: 27px;
  margin-right: 10px;
  margin-bottom: 5px;
}

//nombre de projets

.project_number {
  p {
    width: 100%;
    text-align: right;
    padding-right: 5px !important;
    color: #707070;
    font-family: "work sans";
    font-weight: 400;
  }
}

.addtobook {
  height: 55px;
  border: 1px solid #707070;
  position: absolute;
  bottom: 4px;
  left: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 33px;
  p {
    padding-left: calc(30px + 2%);
    font-size: calc(13px + 0.23vw);
    font-family: "worksans";
    font-weight: 500;
    margin: 0;
  }
  div {
    display: flex;
    p {
      white-space: nowrap;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .add {
      margin-left: 30px;
      background-color: var(--blue);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding-top: 2px;
      padding-bottom: 2px;
      p {
        color: white;
        padding: 0;
        margin: 0;
        padding-left: 25px;
        padding-right: 25px;
        font-weight: 600;
      }
    }
  }
}

.Allproject_project:hover {
  .visibilityhover {
    visibility: hidden;
  }
}

.limiteatteinte {
  position: fixed;
  bottom: 50px;
  width: calc(83.333333% - calc(80px));
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 !important;
  background-color: #636363;
  border: 1px solid #797979;
  color: white;
  font-weight: 400;
  font-family: "worksans";
  height: 56px;
  padding-left: 45px !important;
  padding-right: 45px !important;
  border-radius: 6px;
  margin-left: 15px;
  right: 60px;

  p {
    font-weight: 400;
    font-family: "worksans";
  }
  a {
    color: white;
    height: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 4px;
    border: 1px solid rgb(182, 182, 182);
    background-color: transparent;
  }
  a:hover {
    border: 1px solid rgb(199, 199, 199);
    background-color: #6e6e6e;
  }
  a:active {
    border: 1px solid rgb(199, 199, 199);
    background-color: #686868;
  }
  .limiteatteinteimg {
    position: absolute;
    right: 13px;
    top: 4px;
    cursor: pointer;
    img {
      width: 10px;
      height: 10px;
      filter: invert(1);
    }
    img:hover {
      width: 11px;
      height: 11px;
    }
  }
}

.toolbar2 {
  justify-content: space-between !important;
}

.colorBar {
  background-color: #2e35db !important;
}

.divHeight {
  height: 100%;
  cursor: pointer;
  img {
    height: 32px !important;
    width: auto !important;
    margin-right: 30px;
  }
}

.inputDiv {
  margin-top: 80px !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.buttonDelete {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.searchDiv {
  align-items: center;
  display: flex;
}

.marginRightImg {
  margin-right: 10px;
}
.navbarzone2 {
  position: absolute;
  top: 0;
  left: 0px;
  width: calc(100%);
  z-index: 100000000;
  padding-left: var(--paddingrl) !important;
  padding-right: calc(var(--paddingrl) - 37px) !important;
  cursor: default;
}
.buttonmenu {
  z-index: 100000000;
  margin-right: 45px !important;
}
.toolbar {
  padding-left: var(--paddingrl) !important;
  padding-right: var(--paddingrl) !important;
  display: flex;
  justify-content: space-between;
}
