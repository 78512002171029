//COULEURS
$principal: #f2f2f2;
$bkgbutton: #eeeff6;
//$bkg: #eeeff6;
$bkg: white;
$blue: #4149f6;
$blue_active: linear-gradient(to right, #3c44ef, #2e35e6);
$red: #fd7480;
$green: #2cb08a;
$notactivate: #e2e2ea;

//TEXTE
$texte: #646464;
$textenotactive: #c7c7cd;

//ESPACE NAVBAR
$navspace: 80px;
:root {
  --menuconnect: #ffffff;
}
//FONTS SIZE
//@import "./base/rfs.scss";
$font9: calc(11px + 0.3vw);
$font6_2: calc(8px + 0.2vw);
$font6_3: calc(8px + 0.4vw);
$font7_25: calc(9px + 0.25vw);
$font8_3: calc(10px + 0.3vw);
$font9_3: calc(11px + 0.3vw);
$font9_4: calc(11px + 0.3vw);
$font10_4: calc(13px + 0.5vw);
$font10_5: calc(12px + 0.5vw);

$font12_3: calc(14px + 0.3vw);

//Button
button,
div,
a,
p,
img,
input {
  outline: none !important;
}

::-webkit-scrollbar {
  display: none;
}
::-ms-scrollbar {
  display: none;
}
canvas {
  outline: none;
}

// Fichier GLOBAL !
// Espace droite et gauche dans les projets

@media (min-width: 1200px) {
  .espace_project {
    padding-right: 30px;
    padding-left: 30px;
  }
  .espace_nav {
    padding-right: 15px;
    padding-left: 15px;
  }
}
