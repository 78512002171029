.modal {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 80;
  display: flex;
  align-items: center;
  justify-content: center;
  .background {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.233);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
  }
  .modalContent {
    //  height: 550px;
    width: 600px;
    z-index: 2;
    position: absolute;
    background-color: white;
    .close {
      position: absolute;
      right: 20px;
      top: 15px;
      opacity: 0.7;
      transition: 0.1s;
      cursor: pointer;
      width: 26px;
    }
    .close:hover {
      opacity: 1;
    }
    .title {
      height: 70px;
      display: flex;
      align-items: center;
      padding-left: 30px;
      padding-right: 30px;
      border-bottom: 1px solid #9a9a9a44;
      p {
        font-family: "worksans";
        font-size: var(--regularfont);
        margin: 0;
        padding: 0;
        font-weight: 500;
      }
    }
    .content {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      .oneradio {
        display: flex;
        height: 70px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-family: "worksans";
            font-size: var(--medfont);
            margin: 0;
          }
          img {
            width: 22px;
            filter: invert(1);
            margin-left: 20px;
            height: 22px;
            cursor: pointer;
            opacity: 0.8;
            transition: 0.1s;
          }
          img:hover {
            opacity: 1;
          }
        }
        input[type="radio"] {
          width: 20px;
          height: 20px;
          background-color: var(--colorgraph);
          cursor: pointer;
        }
        input[type="radio"]:checked {
          background-color: #e4e800;
        }
        p {
          margin: 0;
        }
        label {
          margin: 0;
        }
      }
      .padding10 {
        margin-top: 10px;
      }
      .inputtext {
        display: flex;
        height: 60px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        label {
          font-size: var(--medfont);
          margin: 0;
        }
        input[type="text"] {
          // background-color: black;
          // border: 1px solid rgb(107, 107, 107);
          // box-shadow: none;
          // outline: none;
          // color: white;
          // padding-left: 10px;
          // padding-right: 10px;
          // height: 32px;
          // min-width: 340px;
          // font-family: "worksans";
          // font-size: var(--littlefont);
          // font: inherit;
          color: currentColor;
          width: 60%;
          border: 1px solid grey;
          border-radius: 4px;
          height: 1.1876em;
          margin: 0;
          display: block;
          padding: 6px 0 7px;
          min-width: 0;
          background: #fff7be9c;
          box-sizing: content-box;
          letter-spacing: inherit;
          animation-duration: 10ms;
          -webkit-tap-highlight-color: transparent;
        }
        input[type="checkbox"] {
          margin: 0;
          position: relative !important;
        }
        input[type="mail"] {
          color: currentColor;
          width: 60%;
          border: 1px solid grey;
          border-radius: 4px;
          height: 1.1876em;
          margin: 0;
          display: block;
          padding: 6px 0 7px;
          min-width: 0;
          background: #fff7be9c;
          box-sizing: content-box;
          letter-spacing: inherit;
          animation-duration: 10ms;
          -webkit-tap-highlight-color: transparent;
        }
        input:-internal-autofill-selected {
          // background-color: yellow !important;
        }
      }
      .submit {
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;

        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        height: auto;

        input[type="submit"] {
          color: #fff !important;
          background-color: #2e35db;
          border: 1px solid rgb(190, 190, 190);
          border-radius: 4px;
          box-shadow: none;
          outline: none;
          padding: 10px;
          font-family: "worksans";
          font-size: var(--littlefont);
          transition: 0.1s;
        }
        input[type="submit"]:hover {
          background-color: #303f9f;
        }
      }
    }
  }
}
