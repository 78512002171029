:root {
  --paddingrl: calc(5px + 5vw);

  --bluelight: #00b5eb;
  --gray: #d0d0d0;
  --separategray: #a5a5a5;
  --white: #ffffff;

  --bluebackground: #d8f9fe;
  --blue: #2e35db;
  --menuconnect: #ffffff;
}
$principal: #f2f2f2;
$bkgbutton: #eeeff6;
$bkg: #eeeff6;
$blue: #4149f6;
$blue_active: linear-gradient(to right, #2931df, #161ed4);
$red: #fd7480;
$green: #2cb08a;
$notactivate: #d5d5db;

//TEXTE
$texte: #646464;
