/* =================================================

Name:  ROI CSS
Author: MGI Digital Technology, MGI Labs
Design and Developed by: MGI Labs

NB: Ce fichier contient le style du ROI mgi.

=================================================== */
@import "./variable.scss";
::-webkit-scrollbar {
  display: none;
}

form::-webkit-scrollbar:vertical {
  display: block;
  width: 5px;
  border-radius: 20px;
}
form::-webkit-scrollbar:hover {
  display: block;
  width: 7px !important;
  border-radius: 20px;
}
form::-webkit-scrollbar-thumb {
  border-right-width: 5px;
  border-radius: 30px;
  background-color: #eeeeee;
  /* border-top-right-radius: 9px 5px; */
  /* border-bottom-right-radius: 9px 5px; */
}
form::-webkit-scrollbar-thumb:hover {
  background-color: #d1cfcf;
}
//FONTS

/* Poppins */

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 800;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 100;
  src: local("Poppins"),
    url(../../fonts/Poppins/Poppins-Thin.ttf) format("truetype");
}

@media (min-width: 960px) {
  h5 {
    font-size: 1.4rem !important;
    font-weight: 600 !important;
  }
}
@media (min-width: 600px) {
  h5 {
    font-size: 1.25rem !important;
    font-weight: 600 !important;
  }
}
//END FONTS

.toolbar {
  padding-left: var(--paddingrl) !important;
  padding-right: var(--paddingrl) !important;
  display: flex;

  justify-content: space-between;
  div {
    // cursor: pointer;
    img {
      height: 32px !important;
      width: auto !important;
      margin-right: 30px;
    }
  }
}

html {
  font-size: calc(10px + 0.74vw);
  font-weight: 500;
  font-family: "Poppins";
}
body {
  background-color: white !important;
}

// switch page

p,
div,
td,
th,
h1,
h2,
h3,
h4,
h5,
h6,
input,
select,
option {
  font-size: calc(16px + 0.2vw);
  font-weight: 500;
  font-family: "Poppins" !important;
}
p {
  font-weight: 500 !important;
}
td {
  font-weight: 600 !important;
  font-size: 1rem !important;
}

.amortTable {
  font-weight: 400 !important;
  font-size: 1rem !important;
}

th {
  font-size: 1rem !important;
}
input {
  color: black !important;
}

input::placeholder {
  color: rgb(56 55 55 / 59%) !important;
}
::placeholder {
  color: rgb(56 55 55 / 59%) !important;
}
#demo-simple-select-outlined {
  border-color: black !important;
}
a {
  text-decoration: none !important;
}
.importanttext {
  margin-bottom: 10px !important;
  font-size: 0.65rem !important;
  text-align: right;
  color: red;
}
.colorred {
  color: red;
  font-size: 0.8rem !important;
}
.textcenter {
  width: 100%;
  text-align: center;
}
/*
fieldset{
  border-color: black !important;
}
label{
  color: rgb(0 0 0 / 70%) !important;
  font-weight: 500 !important;
}
*/
.fontW600 {
  font-weight: 600 !important;
  h5 {
    font-weight: 600 !important;
  }
}

.required {
  label::after {
    color: red !important;
    content: " *" !important;
    // font-size: 1.1rem !important;
  }
}
.textalignend {
  text-align: end !important;
  input {
    text-align: end !important;
  }
}
.borderform {
  border: 1px solid black !important;
  border-radius: 6px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 4px !important;
  label {
    padding-left: 15px !important;
  }
}

.navbarzone {
  position: fixed;
  top: 0;
  left: 60px;
  width: calc(100%);
  z-index: 100000000;
  height: 64px !important;
  padding-left: var(--paddingrl) !important;
  padding-right: var(--paddingrl) !important;
}

.navbarzone2 {
  position: absolute;
  top: 0;
  left: 0px;
  width: calc(100%);
  z-index: 100000000;
  padding-left: var(--paddingrl) !important;
  padding-right: calc(var(--paddingrl) - 37px) !important;
}

.buttonmenu {
  z-index: 100000000;
  margin-right: 45px !important;
}

.costbtn {
  height: 35px;
  border: 2px solid var(--blue);
  background-color: transparent;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  p {
    margin: 0;
    color: var(--blue);
    padding: 0;
  }
}
.breacktbtn {
  border: 2px solid var(--blue);
  background-color: transparent;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 7px;
  padding-top: 7px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  height: fit-content;
  p {
    margin: 0;
    color: var(--blue);
    padding: 0;
  }
}

.topswitchpagezone {
  height: 85px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  bottom: 2px;
  width: 100%;
  z-index: 1;
  position: absolute;
  left: 0;
  padding-left: calc(5px + 5vw);
  padding-right: calc(5px + 5vw);
  .content {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 10px;
    height: 100%;
    .onezone {
      padding-left: 2vw;
      padding-right: 2vw;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 100%;

      div {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        position: relative;

        button {
          background-color: white;
          border: 2px solid var(--gray);
          border-radius: 38px;
          width: 45px;
          height: 45px;
          cursor: pointer;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            padding: 4px;
            margin: 0;
            width: 100%;
          }
          p {
            opacity: 0;
            transition: 0.3s;

            position: absolute;
            width: 100%;
            bottom: -42px;
            display: flex;
            justify-content: center;
            margin: 0;
            padding: 0;

            font-weight: 500;
            font-size: 0.85rem;

            letter-spacing: 0.35px;
            text-transform: uppercase;

            font-family: "Work sans";
            white-space: nowrap;
          }
        }
        button:hover {
          border: 2px solid var(--bluelight);
          p {
            opacity: 1;
          }
        }
        .selectedbutton {
          border: 2px solid var(--bluelight);
        }
      }
    }
    .separation {
      border-right: 2px solid var(--separategray);
    }

    .zonethree {
      div {
        width: 33.333333%;
        display: flex;
        justify-content: center;
      }
    }
    .zonefive {
      div {
        width: 20%;
        display: flex;
        justify-content: center;
      }
    }
    .zonetwo {
      div {
        width: 50%;
        display: flex;
        justify-content: center;
      }
    }
  }
}

//Prevnext

.prevnext {
  bottom: -85px;
  position: absolute;
  height: 85px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  left: 0;
  padding-right: 0;
  padding-left: 0;
  // padding-right: var(--paddingrl);
  // padding-left: var(--paddingrl);
  background-color: transparent;
  padding-top: 15px;
  .left {
    z-index: 10;
  }
  .right {
    z-index: 10;
  }
}

//Content
.dispflex1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: baseline;
  align-items: flex-start;
  margin-top: 15px;
}

.dispflex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

//Formcontent
.formcontent {
  height: calc(100% - 150px);
  width: 100% !important;
  //  overflow: scroll;
  position: relative;
  padding-bottom: 20px;
  overflow: visible;

  .customer {
    width: 100%;
    margin: auto;
    height: auto;
    //  height: calc(100%) !important;
    overflow: scroll !important;
    .topform {
    }
    .formControl {
      width: 100%;
      display: flex;
      justify-content: center !important;
    }
  }
}

.customer {
}
.threefisrtpage {
  position: absolute;
  width: 100%;
  top: -85px;
}
.moredetail {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  height: auto;
  transition: 0.1s;
  overflow: hidden;
  min-height: 130px;
}
.moredetailhidden {
  height: 0;
  min-height: 0;
  transition: 0.1s;
}
.rotate180 {
  transform: rotate(180deg);
}
.moredetailbuttonzone {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 7.4vw !important;
  .moredetailbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
    p {
      margin-right: 10px;
    }
  }
  .titletop {
    position: absolute;
    top: 63px;
  }
}
.noflexend {
  justify-content: flex-start !important;
}
.inputcontent,
.multiselect,
.textfield {
  display: flex;
  align-items: center;
  justify-content: center;

  .iconcontent {
    display: flex;
    align-items: center;
    justify-content: center;
    //   margin-top: 16px;
    //   margin-bottom: 8px;
  }
}
@media (min-height: 750px) {
  .formcontent {
    //  align-items: center;
    //  display: flex;
  }
}
.formcontent {
  //  align-items: center;
  display: flex;
}
@media (max-height: 830px) {
  .inputcontent {
    display: flex;
    align-items: center;
    justify-content: center;

    .iconcontent {
      display: flex;
      align-items: center;
      justify-content: center;
      //  margin-top: 16px;
      //  margin-bottom: 8px;
    }
    div {
      div {
        margin-top: 3px;
        margin-bottom: 3px;
        div {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  .multiselect {
    display: flex;
    align-items: center;
    justify-content: center;

    .iconcontent {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      margin-bottom: 8px;
    }
    div {
      div {
        margin-top: 6px;
        margin-bottom: 6px;
        div {
          margin-top: 8px;
          margin-bottom: 0;
        }
      }
    }
  }

  .textfield {
    display: flex;
    align-items: center;
    justify-content: center;

    .iconcontent {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      margin-bottom: 8px;
    }
    div {
      div {
        div {
          margin-bottom: 20px;
        }
      }
    }
  }

  .jcontent {
    margin-top: 8px;
  }
}
.textfield {
  div {
    div {
      div {
        margin-bottom: 20px;
      }
    }
  }
}

.algnitem {
  display: flex;
  align-items: center;
}
.jobString {
  display: block !important;
}

.justifyend {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.jcontent {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  width: 100%;
}
.margint15 {
  margin-top: 15px !important;
}
.margint05vw {
  margin-top: 0.3vw;
}
.yellow {
  background-color: #fff7be9c;
  font-weight: 500;
  div {
    // background-color: #fff7be9c;
  }
}
.yellownotransparent {
  background-color: #fffad7;
  font-weight: 500;
  div {
    // background-color: #fff7be9c;
  }
}
.paddinglr10 {
  padding-left: 10px;
  padding-right: 10px;
}
.paddingl10 {
  padding-left: 10px;
}
.paddingr4vw {
  margin-right: 7.4vw !important;
}
.paddingtbm {
  padding-top: calc(9px + 0.2vw) !important;
  padding-bottom: calc(9px + 0.2vw) !important;
}
.alignend {
  display: flex;
  align-items: center;

  flex-wrap: nowrap;
}
.alignend2 {
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.alignend6 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.justifend {
  display: flex;
  justify-content: flex-end;
}

h6 {
  font-weight: 600 !important;
  font-size: 1rem !important;
}

.iconcenter {
  display: flex;
  justify-content: center;
  height: 56px;
  align-items: center;
}

svg,
img {
  height: 27px !important;
  width: 27px !important;
}
.disabled {
  opacity: 0.1;
}
.littletext {
  font-size: 0.8rem !important;
}
.littlefont {
  font-size: 0.7rem !important;
}
.fitcontent {
  height: fit-content;
}

.bluecontent {
  background-color: var(--bluebackground);
  border: 2px solid var(--blue) !important;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.bluebkg {
  background-color: var(--bluebackground);

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.blueInput {
  background-color: var(--bluebackground);
  border: 2px solid var(--blue) !important;
  max-width: 60%;
  font-weight: 600 !important;
  font-size: 1rem !important;
}
.marginr15 {
  // margin-right: 15px !important;
}
.colorblue {
  color: var(--blue) !important;
}
.marginr225vw {
  margin-right: calc(4.25vw) !important;
}
//dahboardimg

.dashboardimg {
  padding-top: 60px;
  margin-top: 50px !important;
  height: 100%;
  width: 80%;
  margin: auto !important;

  justify-content: center;
  img {
    height: auto !important;
    width: 100% !important;
    margin: auto !important;
  }
}

//END dahboardimg

.alphajetimg {
  height: 100%;

  img {
    height: auto !important;
    width: 80% !important;
  }
}
.alphajetmachine {
  position: relative;
  margin-top: 70px;
  width: 100%;

  img {
    height: auto !important;
    width: 100% !important;
  }
}

b {
  color: var(--blue);
  font-weight: 400;
  font-family: "Poppins";
}

.denomination {
  font-size: 0.8rem !important;
}

.alignstart {
  display: flex;
  align-items: flex-start;
  align-content: flex-start !important;
  height: 100% !important;
  justify-content: center;
}
.dashgraph {
  height: 100%;
}

.dashboardzone {
  min-height: calc(100% - 100px);
  height: 100%;
  .dashboardcontent {
    height: 100%;
    .dashgraph {
      height: calc(100% - 100px);
      svg {
        height: auto !important;
        width: 100% !important;
        g {
          text {
            font-size: calc(14px + 0.2vw);
          }
        }
      }
    }
    .dashtext {
      padding-top: 3vw;
      height: fit-content;
      div {
        height: fit-content;
        padding-top: 4px;
        padding-bottom: 4px;
      }
      .lastone {
        padding-bottom: calc(10px + 0.5vw);
      }
      .totalcost {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        height: calc(70px + 0.5vw);
        align-content: center;

        p {
          font-weight: 600 !important;
          display: flex;
          align-items: center;
        }
        .price {
          display: flex;
          align-items: center;
          margin-left: 2vw;
          padding-left: 3vw;
          height: 100%;
          border-top: 2px solid var(--blue);
        }
      }
      .marginauto {
        margin: auto;
      }
      .legend {
        height: 20px;
        width: 20px;
        border-radius: 100px;
        border: 1px solid gray;
        margin: auto;
      }
      .orange {
        background-color: #780695;
      }
      .blue1 {
        background-color: #dd3910;
      }
      .blue2 {
        background-color: #179718;
      }
      .blue3 {
        background-color: #3366cc;
      }
      .yellow1 {
        background-color: #20c1e3;
      }
      .yellow2 {
        background-color: #fd9900;
      }
      .yellow3 {
        background-color: #fdcd00;
      }
      .rose {
        background-color: #fa6bad;
      }
    }
  }
}

//calibration
// demander à Fred si bouton pour afficher ?
.costdetail {
  // display: none;
}

form {
  height: calc(100%) !important;
  overflow: scroll !important;
  align-items: center;
  align-content: center;
  display: flex;
  width: 100%;
}
.italic {
  font-style: italic;
  font-size: 0.8rem !important;
}
.littletext {
  font-size: 0.8rem !important;
}
.textbig {
  font-size: 1.2rem !important;
}
.blueline {
  width: 60%;
  height: 1px;
  background-color: var(--blue);
  position: absolute;
  top: -10px;
}
.relative {
  position: relative;
}

.toptext {
  align-items: flex-start !important;
  margin-top: 15px;
}
.titleroi {
  text-align: center;
  position: relative;
  align-items: center;
  h5 {
    text-align: center;
    width: 100%;
  }
  a {
    position: absolute;
    right: 0;
    height: 100%;
  }
}
.title {
  width: 100% !important;
  height: 36px !important;
  top: 60px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  text-transform: uppercase;
  h1 {
    font-size: calc(14px + 1vw) !important;
    color: white;
  }
}
.bg {
  background: linear-gradient(180deg, var(--blue), 40%, white);
  width: 100% !important;
  height: 100% !important;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
  img {
    padding-bottom: 1%;
    padding-top: 1%;
    width: 72% !important;
    height: auto !important;
  }
}
.bottom {
  width: 100%;
  height: 85px;
  background-color: white;

  bottom: bottom;
  z-index: 1000;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0px;
  justify-content: center;
  .bottomzone {
    width: calc(100% - 120px);
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 150px !important;
      height: auto !important;
    }

    p {
      font-size: calc(12px + 0.4vw) !important;
      text-transform: uppercase !important;
      font-weight: 600;
    }
    .marginleft {
      margin-left: 50px;
    }
  }
}

.displaynone {
  display: none !important;
}

.height100 {
  height: 100% !important;
}
.closedashboard {
  position: absolute;
  right: 0;
  z-index: 2;
  background-color: #2e35db;
  padding: 8px 22px;
  font-size: 0.9375rem;
  color: #fff;
  border-radius: 4px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.inputopv {
  input {
    margin: 0;
    position: absolute;
    width: 27px;
    height: 27px;
  }
}
.inputecoconso {
  span {
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  input {
    margin: 0;
    position: absolute;
    width: 27px;
    height: 27px;
  }
  input[type="checkbox"] {
    margin: 0 !important;
    margin-left: 4px !important;
    margin-top: 4px !important;
    padding: 0 !important;
  }
}
.blueborder {
  border: 1px solid var(--blue);
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 10px;
}

.borderradiusprint {
  border-radius: 100px;
  border: 2px solid var(--blue);
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.bluetext {
  color: var(--blue);
}
.marginb5 {
  margin-bottom: 5px;
}
.marginb12 {
  margin-bottom: 12px;
}
.heightalign {
  height: calc(38px + 0.32vw) !important;
  display: flex;
  align-items: center;
}
.paddingtblittle {
  margin-top: 2px !important;
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}
.fildesetpb {
  padding-bottom: 2px !important;
  input {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  fieldset {
    border: none !important;
  }
}
.breakenven {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  p {
    margin: 0;
    padding: 0;
    font-size: 0.8rem !important;
    font-weight: 600 !important;
    width: 100%;
    text-align: center;
  }
}

.marginBottom {
  margin-bottom: 2px;
}

.accueilButton {
  color: #fff;
  background-color: #2e35db !important;
  &:hover {
    background-color: #303f9f !important;
  }
}
.sizemax {
  width: 100%;
}
.flexwrap {
  flex-wrap: wrap;
  display: flex;
}
.showtable {
  margin-top: 0%;
  transition: 0.12s;
}
.hidetable {
  margin-top: -100% !important;
}
.aligncenter {
  align-items: center;
  display: flex;
}
.eyeimg {
  margin-left: 15px;
  cursor: pointer;
  width: 22px !important;
  height: auto !important;
}

.littleradio {
  svg {
    height: 20px !important;
    width: 20px !important;
  }
}
.radiozone {
  justify-content: flex-end;
  width: 100%;
}

.radiocontent {
}

.colorBaraccueil {
  background-color: transparent !important;
}
.flexstart {
  align-items: start;
  .iconcontent {
    margin-top: 3px;
  }
}
.bottomtext {
  align-items: flex-end;
  align-content: flex-end;
}
.height50 {
  height: 38.25px;
  h6 {
    border-radius: 4px;

    font-size: 1rem !important;
    line-height: 1;
  }
}
